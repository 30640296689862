
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.amp-rate--wrapper::v-deep {
    text-align: center;

    .amp-rate__title {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        color: black;
    }

    .amp-rate__progress--wrapper {
        display: flex;
        align-items: flex-end;
        justify-content: center;

        .amp-rate__progress {
            display: flex;
            align-items: center;
            justify-content: center;
            transform: rotate(45deg);
            height: 48px;
            width: 48px;
            border-radius: 50%;
            border: 4px solid transparent;
            transition: border 0.5s;

            &.progress-25 {
                border-top: 4px solid $primary-color;
            }

            &.progress-50 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
            }

            &.progress-75 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
                border-bottom: 4px solid $primary-color;
            }

            &.progress-100 {
                border-top: 4px solid $primary-color;
                border-right: 4px solid $primary-color;
                border-bottom: 4px solid $primary-color;
                border-left: 4px solid $primary-color;
            }

            .icon {
                transform: rotate(-45deg);
                transition: transform 0.5s;

                &.up {
                    transform: rotate(-90deg);
                }

                &.down {
                    transform: rotate(0deg);
                }
            }
        }

        .amp-rate__progress-count {
            font-size: 20px;
            font-weight: 500;
            margin-left: 0.75em;
        }
    }

    small {
        font-size: 12px;
    }
}
