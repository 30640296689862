
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.modal-wrapper::v-deep {
    .v-card > .v-card__subtitle,
    .v-card__text {
        color: $secondary-color;
    }

    .v-sheet {
        box-shadow: none;
    }

    .v-btn__content {
        max-width: 100%;
        white-space: normal;
    }
}

.close-dialog-btn {
    position: absolute;
    top: 1em;
    right: 1em;
}
