
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.slider-container::v-deep {
    background-color: $blueish;

    .slider-wrapper {
        background-image: url('@/assets/img/slider-background.svg');
        background-repeat: no-repeat;
        background-position-y: bottom;
        background-position-x: right;
        padding-top: 0;
        padding-bottom: 0;
        background-size: 35%;

        .v-carousel {
            .v-window__container {
                .v-window__prev {
                    margin: 0 !important;
                    background-color: unset !important;

                    .v-btn:before {
                        background-color: unset !important;
                    }
                }

                .v-window__next {
                    margin: 0 !important;
                    background-color: unset !important;

                    .v-btn:before {
                        background-color: unset !important;
                    }
                }
            }

            .v-carousel__controls {
                .v-item-group {
                    .v-carousel__controls__item {
                        margin: 0 6px 0 0;

                        &:before {
                            background-color: transparent !important;
                        }

                        .v-btn__content svg {
                            opacity: 1 !important;
                        }

                        .v-ripple__container {
                            visibility: hidden;
                        }

                        &:not(.v-item--active) {
                            .v-btn__content svg {
                                opacity: 0.2 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 600px) {
    .slider-container::v-deep {
        .slider-wrapper {
            background-size: contain;

            .v-carousel {
                height: 440px !important;

                .v-carousel__item {
                    height: 440px !important;
                }
            }
        }
    }
}

@media (min-width: 1900px) {
    .slider-container::v-deep {
        .slider-wrapper {
            background-size: contain;
        }
    }
}
