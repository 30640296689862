
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

:root {
    --needle-angle: 0deg;
}

.brand-mentions--container {
    .brand-mentions__loader--wrapper {
        .v-card__text {
            height: 100%;
        }
    }

    .brand-mentions__stats--wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .v-avatar {
            .v-icon {
                transform: rotate(-45deg);
            }
        }

        .v-card__text {
            padding: 0;
            width: fit-content;

            .v-card__title {
                font-weight: 500;
                font-size: 18px;
                color: black;
                padding: 16px 8px 8px;
            }

            .v-card__subtitle {
                font-weight: 400;
                font-size: 12px;
                color: $secondary-color;
                padding-left: 10px;
            }
        }
    }
}

.brand-momentum--container::v-deep {
    .brand-momentum__loader--wrapper {
        .v-card__text {
            height: 23.5em;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .v-skeleton-loader {
                &.image-loader {
                    height: 20em;

                    .v-skeleton-loader__image {
                        height: 100%;
                    }
                }
            }
        }
    }

    .brand-momentum__guage--wrapper {
        .v-card__text {
            height: 23.5em;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: center;

            .guage-heading {
                text-align: center;
                .guage-title {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 40px;
                    color: black;
                    margin: 0;
                }

                small {
                    font-size: 12px;
                }
            }

            .guage--container {
                height: 100%;
                width: 100%;

                .guage--wrapper {
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;

                    height: inherit;
                    width: inherit;
                    background-image: url('@/assets/img/guage.svg');
                    background-position: center;

                    .guage-needle {
                        height: 7em;
                        width: 10em;
                        background-image: url('@/assets/img/needle.svg');
                        background-position: calc(50% - 26px) 50%;
                        background-size: 5em;
                        transform: rotate(var(--needle-angle));
                        transition: transform 0.5s;
                    }
                }
            }
        }
    }
}
