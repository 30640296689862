
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.default-stats--container {
    padding: 1.5em;

    .default-stats--wrapper {
        background-color: #e8f4fe;
        border-radius: 8px;
        padding: 1em;

        .header-text {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 1rem;
        }

        .footer-text {
            font-weight: 400;
            font-size: 12px;
        }
    }
}
