
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.loader--wrapper::v-deep {
    .v-card__text {
        height: 100%;

        .v-skeleton-loader {
            &.image-loader {
                height: 320px;

                .v-skeleton-loader__image {
                    height: 100%;
                    border-radius: 4px;
                }
            }
        }
    }
}
