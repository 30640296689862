
                                @import '@/assets/sass/vuetify/variables.scss';
                                @import '@/assets/sass/colors.scss';
                            

.stat-card {
    border-radius: 24px !important;
    height: 8em;

    .stat-card--count {
        display: inline-block;
        background-color: $primary-color;
        padding: 8px;
        min-width: 3em;
        text-align: center;
        border-radius: 12px;
        color: white;
        font-weight: bold;
    }

    .stat-card--text {
        font-weight: 500;
        font-size: 14px;
        margin-top: 0.5em;
        color: black;
    }
}
